<template>
  <div>
    <!--begin::BusinessAccount-->
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Business Account View</h3>
        </div>
        <div class="card-toolbar">
          <div class="example-tools justify-content-center">
            <div class="my-2">
              <button
                type="button"
                class="mt-3 mb-3 mr-3 v-btn v-btn--depressed v-btn--flat v-btn--outlined theme--light v-size--default indigo--text"
                v-if="user_permissions.includes('chapter_add_accounts') || user_permissions.includes('se_add_accounts')"
              >
                <span
                  class="v-btn__content"
                  @click="redirectToEditBA($route.params.id)"
                >
                  Edit
                </span>
              </button>
              <button
                type="button"
                class="mt-3 mb-3 v-btn v-btn--depressed v-btn--flat v-btn--outlined theme--light v-size--default indigo--text"
              >
                <span
                  class="v-btn__content"
                  @click="redirectToBusinessAccountList()"
                >
                  Back
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <form
          class="form"
          novalidate="novalidate"
          id="kt_business_account_form"
        >
          <!--begin::Example-->
          <div class="example mb-10">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row no-gutters>
                <v-col cols="12" sm="6">
                  <b>Name:</b> {{ business_account.name }}
                </v-col>
                <v-col cols="12" sm="6">
                  <b>Account Holder: </b>{{ business_account.account_holder }}
                </v-col>
              </v-row>

              <v-row no-gutters class="mt-3">
                <v-col cols="12" sm="6">
                  <b>Account Type: </b>{{ business_account.account_type }}
                </v-col>
                <v-col cols="12" sm="6">
                  <b>URL: </b>{{ business_account.url }}
                </v-col>
              </v-row>

              <v-row no-gutters class="mt-3">
                <v-col cols="12" sm="6">
                  <b>Login Id: </b>{{ business_account.login_id }}
                </v-col>
                <v-col cols="12" sm="6">
                  <b>Password: </b>
                  <template v-if="pass_show">
                    {{ hidden_password }}
                    <v-icon
                      @click="togglePassword()"
                      color="#B5B5C3"
                      style="line-height: 10px;"
                    >
                      mdi-eye
                    </v-icon>
                  </template>
                  <template v-else>
                    {{ business_account.password }}
                    <v-icon
                      @click="togglePassword()"
                      color="#B5B5C3"
                      style="line-height: 10px;"
                    >
                      mdi-eye-off
                    </v-icon>
                  </template>
                </v-col>
              </v-row>

              <v-row no-gutters class="mt-3">
                <v-col cols="12" sm="6">
                  <b>Cost/Subscription Amount: </b>{{ business_account.cost }}
                </v-col>
                <v-col cols="12" sm="6">
                  <b>Subscription Duration: </b>
                  {{ business_account.subscription_duration }}
                </v-col>
              </v-row>

              <v-row no-gutters class="mt-3">
                <v-col cols="12" sm="12">
                    <b>Renewal Date: </b>{{ business_account.renewal_date }}
                </v-col>
              </v-row>

              <v-row no-gutters class="mt-3">
                <v-col cols="12" lg="12">
                  <b>Note: </b>{{ business_account.note }}
                </v-col>
              </v-row>
            </v-form>
          </div>
        </form>
      </div>
    </div>
    <!--end::BusinessAccount-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Vuetify from "vuetify";
import axios from "axios";
import moment from "moment";
import { format, parseISO } from "date-fns";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_COMPANY } from "@/core/services/store/user.module";
import { GET_COMPANY_USERS } from "@/core/services/store/user.module";
import { GET_SUBSCRIPTION_DURATION } from "@/core/services/store/businessaccount.module";
import {
  STORE_BUSINESS_ACCOUNT,
  UPDATE_BUSINESS_ACCOUNT
} from "@/core/services/store/businessaccount.module";
import Swal from "sweetalert2";
import KTUtil from "@/assets/js/components/util";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

export default {
  name: "businessAccountCreate",
  vuetify: new Vuetify(),
  components: {},
  data() {
    return {
      user_permissions:[],
      business_account: {
        name: "",
        account_holder: "",
        login_id: "",
        password: "",
        account_type: "",
        url: "",
        renewal_date: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
        cost: "0.00",
        note: "",
        subscription_duration: ""
      },
      hidden_password: "********",
      pass_show: true,
      company_users: "",
      company_user_list: [],
      business_account_types_list: [],
      valid: true,
      disable_btn: false,
      nameRules: [
        v => !!v || "Name is required",
        v => (v && v.length <= 25) || "Name must be less than 25 characters"
      ],
      urlRules: [
        v => !!v || "URL is required",
        v =>
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g.test(
            v
          ) || "URL must be valid"
      ],
      edit_form: false,
      subscription_duration: [],
      show1: true
    };
  },
  watch: {
    getUserPermissions: function(newVal) {
      this.user_permissions = newVal;
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    ...mapGetters(["companyUsers"]),
    ...mapGetters(["getStateSubscriptionDuration"]),
    ...mapGetters(["getUserPermissions"]),
    computedDateFormattedMomentjs() {
      return this.business_account.renewal_date
        ? moment(this.business_account.renewal_date).format("MM-DD-YYYY")
        : "";
    }
  },
  mounted() {
    let context = this;
    context.getBusinessAccountTypes();
    context.getCompanyUsers();
    context.getSubscriptionDuration();
    if (this.$route.params.id) {
      context.edit_form = true;
      axios({
        method: "get",
        url: "business-account/" + this.$route.params.id,
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          if (result.data.business_account) {
            context.business_account = result.data.business_account;
            context.business_account.account_holder =
              result.data.business_account.selected_user;
          } else {
            context.$router.go(-1); //go back to list
            context.disable_btn = false;
            Swal.fire(result.data.error, "", "error");
          }
        },
        function() {
          context.$router.go(-1); //go back to list
          context.disable_btn = false;
          Swal.fire("Error", "Business Account not found!", "error");
        }
      );
    }

    // set the tab from previous
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Business Account" }]);

    const business_account_form = KTUtil.getById("kt_business_account_form");

    this.fv = formValidation(business_account_form, {});
    this.$store.dispatch(GET_COMPANY);
  },
  destroyed() {
    localStorage.removeItem("builderTab");
  },
  methods: {
    togglePassword() {
      this.pass_show = !this.pass_show;
    },
    redirectToEditBA(ba_id) {
      this.$router.push({
        name: "businessAccount.edit",
        params: { id: ba_id }
      });
    },
    getSubscriptionDuration() {
      // let context = this;
      this.$store.dispatch(GET_SUBSCRIPTION_DURATION).then(() => {
        if (this.getSubscriptionDuration) {
          this.subscription_duration = this.getStateSubscriptionDuration;
        }
      });
    },
    getBusinessAccountTypes() {
      let context = this;
      axios({
        method: "get",
        url: "business-account-types",
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(function(result) {
        if (result.data.business_account_types) {
          result.data.business_account_types.forEach(ba_type => {
            context.business_account_types_list.push(ba_type.name);
          });
        }
      });
    },
    getCompanyUsers() {
      let context = this;
      this.$store.dispatch(GET_COMPANY_USERS).then(() => {
        if (this.companyUsers) {
          this.company_users = this.companyUsers;

          this.company_users.forEach(user => {
            this.company_user_list.push(user.first_name + " " + user.last_name);
          });
        } else {
          axios({
            method: "get",
            url: "get-company-users",
            baseURL: process.env.VUE_APP_ACBACK_URL
          }).then(function(result) {
            if (result.data.company_users) {
              result.data.company_users.forEach(user => {
                context.company_user_list.push(
                  user.first_name + " " + user.last_name
                );
              });
            }
          });
        }
      });
    },
    updateBusinessAccount() {
      this.$store
        .dispatch(UPDATE_BUSINESS_ACCOUNT, this.business_account)
        .then(() => {
          this.$router.go(-1);
        });
    },
    redirectToBusinessAccountList() {
      let context = this;
      context.$router.go(-1);
    },
    submitBusinessAccount() {
      let context = this;
      this.$store
        .dispatch(STORE_BUSINESS_ACCOUNT, this.business_account)
        .then(() => {
          context.disable_btn = false;
          this.$router.go(-1);
        });
    },
    validateEditForm() {
      let context = this;
      context.disable_btn = true;
      this.fv.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
      if (this.$refs.form.validate()) {
        this.updateBusinessAccount();
      } else {
        this.snackbar = true;
      }
    },
    validate() {
      let context = this;
      context.disable_btn = true;
      this.fv.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
      if (this.$refs.form.validate()) {
        this.submitBusinessAccount();
      } else {
        this.snackbar = true;
      }
    }
  }
};
</script>
